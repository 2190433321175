<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        <!-- <Button
          label="اضافة جديد"
          icon="pi pi-plus" v-tooltip="'اضافه جديد'"
          class="p-ml-2 p-button-success"
          v-if="$checkRoles('contractsAdd')"
          @click="$router.push('contracts/add')"
        />
        <Button
          label="حذف"
          icon="pi pi-trash" v-tooltip="'حذف'"
          @click="deleteAll"
          v-if="$checkRoles('contractsDelete')"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
        <Button icon="pi pi-print" @click="printPage()" label="طباعه" />
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->

        <Calendar
          placeholder="من"
          v-model="search.start"
          dateFormat="dd/mm/yy"
        />
        <Calendar
          style="margin-right: 9px;"
          placeholder="الى"
          v-model="search.end"
          dateFormat="dd/mm/yy"
        />
        <Button
          style="margin-right: 9px;"
          class="p-button-success"
          icon="pi pi-search"
          @click="searchAdd()" v-tooltip="'بحث'"
        />
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)" v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      id="print"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100,500,1000,10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه الدفعات
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <Column
        field="id"
        header="رقم الدفعه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['id']"
            class="p-column-filter"
            placeholder="بحث برقم الدفعه"
          />
        </template>
      </Column>
      <Column
        field="contractsId.code"
        header="رقم العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['contractsId.code']"
            class="p-column-filter"
            placeholder="بحث رقم العقد"
          />
        </template>
      </Column>
      <Column
        field="contractsId.clientsId.name"
        header="اسم العميل"
        :sortable="true"
        filterMatchMode="contains"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['contractsId.clientsId.name']"
            class="p-column-filter"
            placeholder="بحث اسم العميل"
          />
        </template>
      </Column>
      <Column
        field="name"
        header="اسم الدفعه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['name']"
            class="p-column-filter"
            placeholder="بحث باسم الدفعه"
          />
        </template>
      </Column>

      <Column field="paymentType" header="حاله الدفعه" :sortable="true">
        <template #body="slotProps">
          <span v-if="slotProps.data.paymentType == null">
            لم يتم التحصيل
          </span>
          <span v-if="slotProps.data.paymentType == 'cach'">
            كاش
          </span>
          <span v-if="slotProps.data.paymentType == 'check'">
            شيك
          </span>
          <span v-if="slotProps.data.paymentType == 'transfer'">
            تحويل
          </span>
        </template>
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="paymentType"
            name="paymentType"
            v-model="filters['paymentType']"
            placeholder="بحث بنوع الدفع"
          >
            <option :value="null">الكل </option>
            <option value="cach">
              كاش
            </option>
            <option value="check">
              شيك
            </option>
            <option value="transfer">
              تحويل
            </option>
          </select>
        </template>
      </Column>
      <Column
        field="date"
        header="تاريخ تحصيل الدفعه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ تحصيل الدفعه"
          />
        </template>
      </Column>
      <Column
        field="price"
        header="قيمه الدفعه"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['price']"
            class="p-column-filter"
            placeholder="بحث قيمه الدفعه"
          />
        </template>
      </Column>
      <Column field="usersId.name" header="اسم المحصل" :sortable="true">
        <template #filter>
          <select
            class="form-select form-control p-column-filter"
            id="usersId"
            name="usersId"
            v-model="filters['usersId.name']"
            placeholder="بحث باسم المحصل"
          >
            <option :value="null">الكل </option>
            <option v-for="item of usersList" :key="item.id" :value="item.id">{{
              item.name
            }}</option>
          </select>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      loading: true,
      filters: {},
      branchesList: [],
      usersList: [],
      listx: [],
      search: {
        start: null,
        end: null,
      },
    };
  },
  methods: {
    printPage() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=1024,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(this.$htmlPrint(stylesHtml, prtHtml));

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
    searchAdd() {
      if (this.search.start && !this.search.end) {
        const start = new Date(this.search.start).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() >= start;
        });
      }

      if (!this.search.start && this.search.end) {
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return new Date(item.createdAt).getTime() <= end;
        });
      }

      if (this.search.start && this.search.end) {
        const start = new Date(this.search.start).getTime();
        const end = new Date(this.search.end).getTime();
        this.list = this.listx.filter((item) => {
          return (
            new Date(item.createdAt).getTime() >= start &&
            new Date(item.createdAt).getTime() <= end
          );
        });
      }
    },
    getData() {
      this.$http.get(`payments/getReport`).then(
        (response) => {
          this.list = response.data;
          this.listx = response.data;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
  },
  created() {
    this.$http.get(`users`).then((res) => {
      this.usersList = res.data;
    });
    this.getData();
  },
};
</script>

<style>
.roles label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
